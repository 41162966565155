import React from "react";
import PageWrapper from "../../components/PageWrapper";
import { Card, CardContent, Typography } from "@material-ui/core";
import DeviceList from "./components/DeviceList";
import CounterList from "./components/CounterList";
import MetricList from "./components/MetricList";

function ConfigurationPage() {
  return (
    <PageWrapper margin={20}>
      <DeviceList />
      <CounterList />
      <MetricList />
    </PageWrapper>
  );
}

export default ConfigurationPage;
