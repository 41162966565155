import { useState, useEffect } from "react";

export function useFormInput(defaultValue) {
  const [value, setValue] = useState(defaultValue);

  function onChange({ target }) {
    setValue(target.value);
  }

  return { value, onChange };
}

export function useWindowSize() {
  const [height, setHeight] = useState(window.innerHeight);

  function handleResize() {
    setHeight(window.innerHeight);
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return window.removeEventListener("resize", handleResize);
  }, []);

  return { height };
}
