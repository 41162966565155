import React, { useState } from "react";
import {
  Typography,
  Grid,
  makeStyles,
  CircularProgress,
  Card,
  CardContent,
  useMediaQuery,
} from "@material-ui/core";
import WorkflowWidget from "./components/WorkflowWidget";
import { useContext } from "react";
import { MetricsContext, QueryContext } from "../../../../contextStore";
import { useEffect } from "react";
import { getMetricsValues } from "../../../../services/MetricValueService";
import HistoricMetricWidget from "./components/HistoricMetricWidget";
import { useRef } from "react";
import MetricDial from "./components/MetricDial";
import DashToolbar from "./components/DashToolbar";
import { primaryDark } from "../../../../styles/colors";
import { isHiddenMetric } from "../../../../utils";
import HistogramWidget from "../../../../components/HistogramWidget";
import MetricDialContainer from "./components/MetricDialContainer";

const useStyles = makeStyles(() => ({
  container: {
    padding: "0 20px",
    backgroundColor: "rgb(34, 40, 51)",
  },
  gridItem: {
    // margin: "20px 0px",
  },
  spinner: {
    margin: 40,
  },
  dialCard: {
    flexDirection: "row",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    width: "100%",
    flexWrap: "wrap",
  },
  column: {
    display: "flex",
    flexDirection: "column",
  },
  radialDescription: {
    textAlign: "center",
  },
}));

const Dashboard = React.memo(() => {
  const classes = useStyles();
  const { metrics } = useContext(MetricsContext);
  const [metricValues, setMetricValues] = useState([]);
  const query = useContext(QueryContext);
  const minWidthForHistoricCharts = useMediaQuery("(min-width:1200px)");
  const interval = useRef(false);
  const timeIntervalRef = useRef(false);

  async function loadData() {
    const promises = [];
    query.show();
    for (let i = 0; i < metrics.length; i++) {
      const metric = metrics[i];
      promises.push(getMetricsValues(metric.metricId, timeIntervalRef.current));
    }

    try {
      const values = await Promise.all(promises);
      setMetricValues(values);
      setTimeout(() => {
        query.hide();
      }, 500);
      if (!interval.current) {
        interval.current = setInterval(() => {
          loadData();
        }, 150000000);
      }
    } catch (e) {
      query.hide();

      console.log("e: ", e);
    }
  }

  useEffect(() => {
    loadData();
    return () => {
      if (interval.current) {
        clearInterval(interval.current);
        interval.current = false;
      }
    };
  }, []);

  function shouldShowMetric(metric) {
    if (!metric.length) return null;
    const found = metrics.find((item) => item.metricId === metric[0].metricId);
    if (!found || isHiddenMetric(found)) {
      return null;
    }
    return true;
  }

  return (
    <React.Fragment>
      {!metricValues.length && query.active && (
        <React.Fragment>
          <Typography style={{ marginTop: 40 }} variant="h6">
            Loading Dashboard...
          </Typography>
        </React.Fragment>
      )}
      {!query.active && metricValues.length && (
        <Grid
          id="Home_Page"
          wrap="wrap"
          spacing={2}
          className={classes.container}
          container
        >
          <Grid item style={{ marginTop: 20 }} xs={12}>
            <DashToolbar
              timeIntervalRef={timeIntervalRef}
              loadData={loadData}
            />
          </Grid>

          <Grid
            className={classes.gridItem}
            xs={minWidthForHistoricCharts ? 6 : 12}
            item
          >
            <WorkflowWidget edit metricValues={metricValues} />
          </Grid>
          <Grid
            className={classes.gridItem}
            xs={minWidthForHistoricCharts ? 6 : 12}
            item
          >
            <MetricDialContainer
              timeInterval={timeIntervalRef.current}
              metricValues={metricValues}
            />
          </Grid>
          {metrics.map((metric, idx) => {
            if (metric.type !== "AFLUENCIA") return false;

            return (
              <Grid className={classes.gridItem} xs={12} item>
                <HistogramWidget
                  index={idx}
                  timeInterval={timeIntervalRef.current}
                  data={metricValues[idx]}
                  metric={metric}
                />
              </Grid>
            );
          })}

          {metricValues.map((metric, idx) => {
            if (!shouldShowMetric(metric)) return null;
            return (
              <Grid
                className={classes.gridItem}
                xs={
                  metrics[idx].total ? 12 : minWidthForHistoricCharts ? 4 : 12
                }
                item
              >
                <HistoricMetricWidget
                  metricData={metric}
                  index={idx}
                  metric={metrics[idx].metricName}
                  max={metrics[idx].max}
                />
              </Grid>
            );
          })}
        </Grid>
      )}
    </React.Fragment>
  );
});

export default Dashboard;
