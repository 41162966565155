import HttpService from "../HttpService";
import { apiEndpoint } from "../../config";
import moment from "moment-timezone";

const metricUrl = `${apiEndpoint}/metric-value`;

export async function getMetricsValues(metricId, time) {
  try {
    let url = `${metricUrl}?metricId=${metricId}`;
    if (time) {
      url += `&since=${moment(time).tz("Europe/Madrid").format("x")}`;
    }

    const response = await HttpService.get(url);
    const { data } = response;
    return data;
  } catch (e) {
    throw e;
  }
}
