import React, { useState } from "react";
import {
  makeStyles,
  Typography,
  Card,
  CardContent,
  ButtonGroup,
  Button,
} from "@material-ui/core";
import { isHiddenMetric } from "../../../../../../utils";
import { useContext } from "react";
import { MetricsContext } from "../../../../../../contextStore";
import MetricDial from "../MetricDial";
import { useMemo } from "react";
import moment from "moment-timezone";
import { useEffect } from "react";

const useStyles = makeStyles(() => ({
  container: {
    padding: "0 20px",
    backgroundColor: "#3E414F",
  },
  gridItem: {
    // margin: "20px 0px",
  },
  spinner: {
    margin: 40,
  },
  dialCardContainer: {
    display: "flex",
    flexDirection: "column",
  },
  dialCard: {
    flexDirection: "row",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    width: "100%",
    flexWrap: "wrap",
  },
  column: {
    display: "flex",
    flexDirection: "column",
  },
  radialDescription: {
    textAlign: "center",
  },
}));

const MetricDialContainer = React.memo(({ metricValues, timeInterval }) => {
  const { metrics } = useContext(MetricsContext);
  const [mode, setMode] = useState("LIVE");

  function shouldShowMetric(metric) {
    if (!metric.length) return null;
    const found = metrics.find((item) => item.metricId === metric[0].metricId);
    if (!found || isHiddenMetric(found)) {
      return null;
    }
    return true;
  }

  const classes = useStyles();

  const isToday = useMemo(() => {
    return !timeInterval || moment().isSame(timeInterval, "day");
  }, [timeInterval]);

  useEffect(() => {
    if (!isToday) {
      setMode("AVERAGE");
    }
  }, [metricValues]);

  return (
    <Card className={classes.dialCardContainer}>
      <ButtonGroup
        style={{ alignSelf: "flex-end", marginRight: 20, marginTop: 20 }}
        color="secondary"
      >
        <Button
          onClick={() => setMode("LIVE")}
          disabled={!isToday}
          variant={mode === "LIVE" ? "contained" : "outlined"}
        >
          Live
        </Button>
        <Button
          onClick={() => setMode("AVERAGE")}
          variant={mode === "AVERAGE" ? "contained" : "outlined"}
        >
          Average
        </Button>
      </ButtonGroup>
      <CardContent className={classes.dialCard}>
        {metricValues.map((metric, idx) => {
          if (!shouldShowMetric(metric)) return null;
          return (
            <div
              key={metric.metricId}
              style={{ margin: "20px 0px" }}
              className={classes.column}
            >
              <MetricDial
                metricData={metric}
                metric={metrics[idx].metricName}
                max={metrics[idx].max}
                index={idx}
                mode={mode}
              />
            </div>
          );
        })}
      </CardContent>
    </Card>
  );
});

export default MetricDialContainer;
