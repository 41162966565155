import React, { useContext } from "react";
import { makeStyles, Typography, NativeSelect } from "@material-ui/core";
import { ViewContext, ProjectContext } from "../../../../contextStore";
import { VIEWS } from "../../../../AppRouter";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    marginTop: 5,
  },
  currentProject: {
    marginLeft: 20,
    marginTop: 5,
    position: "relative",
    bottom: 2,
  },
  projectSelector: {
    marginLeft: 20,
  },
}));

function ProjectSelector({ projects, currentProject }) {
  const classes = useStyles();
  const { setCurrentView } = useContext(ViewContext);
  const { setCurrentProject } = useContext(ProjectContext);

  function onChange(e) {
    if (e.target.value === "CREATE") {
      e.stopPropagation();
      setCurrentView(VIEWS.CREATE_PROJECT);
    } else {
      setCurrentView(VIEWS.MAIN);
      const prj = projects.find(
        (project) => project.projectId === e.target.value
      );
      setCurrentProject(prj);
    }
  }
  return (
    <div className={classes.container}>
      <NativeSelect
        className={classes.projectSelector}
        value={currentProject.projectId}
        disableUnderline
        onChange={onChange}
      >
        {projects.map((project) => (
          <option key={project.projectId} value={project.projectId}>
            {project.name}
          </option>
        ))}
        {/* <option value="CREATE" onChange={onChange}>
          Create new project
        </option> */}
      </NativeSelect>
    </div>
  );
}

export default ProjectSelector;
