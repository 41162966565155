import { cognitoConfig } from "../../config";

const AmazonCognitoIdentity = require("amazon-cognito-identity-js");

const { UserPoolId, ClientId } = cognitoConfig;

const poolData = { UserPoolId, ClientId };

// eslint-disable-next-line no-undef

let cognitoSession = false;
let currentCognitoUser = false;

export function signUpUser(username, password) {
  return new Promise((resolve, reject) => {
    const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
    userPool.signUp(username, password, null, null, (err, data) => {
      if (err) {
        reject(err);
      } else {
        resolve(data);
      }
    });
  });
}

export const signInUser = async ({ email, password }) => {
  const authenticationData = {
    Username: email,
    Password: password,
  };
  const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(
    authenticationData
  );
  const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
  const userData = {
    Username: email,
    Pool: userPool,
  };

  const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
  currentCognitoUser = cognitoUser;

  try {
    await new Promise((resolve, reject) => {
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: function (result) {
          const accessToken = result.getAccessToken().getJwtToken();
          resolve(accessToken);
        },
        onFailure: function (err) {
          const { message } = err;
          reject(message);
        },
      });
    });
    return "success";
  } catch (e) {
    throw e;
  }
};

export const signOut = () => {
  const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
  const cognitoUser = userPool.getCurrentUser();
  currentCognitoUser = false;
  if (cognitoUser) {
    cognitoUser.signOut();
    window.location.href = "/";
  } else {
    throw new Error("No user found");
  }
};

export const changePassword = ({ newPassword, oldPassword }) =>
  new Promise((resolve, reject) => {
    const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
    const cognitoUser = userPool.getCurrentUser();
    if (cognitoUser) {
      cognitoUser.setSignInUserSession(cognitoSession);
      cognitoUser.changePassword(oldPassword, newPassword, (err, resp) => {
        if (err) {
          reject(JSON.stringify(err));
        } else {
          resolve("Password Changed");
        }
      });
    } else {
      reject("No User Detected");
    }
  });

export const getSession = async () => {
  const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
  const cognitoUser = userPool.getCurrentUser();
  if (cognitoUser != null) {
    try {
      const response = await new Promise((resolve, reject) => {
        cognitoUser.getSession(function (err, session) {
          if (err) {
            reject(err);
            return;
          }
          const sessionValid = session.isValid();

          if (sessionValid) {
            cognitoSession = session;
            currentCognitoUser = cognitoUser;
            resolve(cognitoUser);
          } else {
            reject("Session is not valid.");
          }
        });
      });
      return response;
    } catch (e) {
      throw e;
    }
  }
  return false;
};

export const forgotPassword = (userId) => {
  const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
  const userData = {
    Username: userId,
    Pool: userPool,
  };
  const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
  return new Promise((resolve, reject) => {
    cognitoUser.forgotPassword({
      onSuccess: (result) => {
        resolve(result);
      },
      onFailure: (err) => {
        reject(err);
      },
    });
  });
};

export const submitNewPassword = ({
  verificationCode,
  newPassword,
  userId,
}) => {
  return new Promise((resolve, reject) => {
    const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
    const userData = {
      Username: userId,
      Pool: userPool,
    };
    const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
    cognitoUser.confirmPassword(verificationCode, newPassword, {
      onSuccess: (resp) => resolve(resp),
      onFailure: (err) => reject(err),
    });
  });
};

export function checkForTokenValidity(user) {
  const { accessToken } = user;
  const { payload } = accessToken;
  let { exp } = payload;
  exp = Number(`${exp}000`);
  const currentTime = Date.now();
  if (currentTime > exp) {
    return false;
  }
  return true;
}

export function refreshSession(refreshToken) {
  return new Promise((resolve) => {
    const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
    const cognitoUser = userPool.getCurrentUser();
    cognitoUser.refreshSession(refreshToken, (_, newSession) => {
      resolve(newSession);
    });
  });
}

export const getCurrentCongitoUser = () => {
  if (currentCognitoUser) {
    return currentCognitoUser.signInUserSession;
  }
  return false;
};
