import React from "react";
import {
  Card,
  CardContent,
  Typography,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import ReactApexChart from "react-apexcharts";
import { defaultChartOptions } from "../../../../../../services/ChartingService";
import { primaryDark } from "../../../../../../styles/colors";
import { useMemo } from "react";
import { getMaxColor } from "../../../../../../utils";

const useStyles = makeStyles(() => ({
  container: {
    minWidth: 300,
  },
  radialDescription: {
    textAlign: "center",
  },
}));

const COLORS = [
  "#1068ef",
  "#447825",
  "#9100ff",
  "#25da57",
  "#bb35ca",
  "#5dadec",
  "#A64CA6",
  "#FF6666",
];

function MetricDial({ metricData, max, metric, index, mode }) {
  const classes = useStyles();
  let value = useMemo(() => {
    if (!metricData.length) return 0;
    if (mode === "LIVE") {
      if (!metricData.length) {
        return { value: 0, pct: 0 };
      }

      const value = metricData[metricData.length - 1].value;

      return {
        value: value < 0 ? 0 : value,
        pct: Math.floor(value < 0 ? 0 : (value / max) * 100),
      };
    }
    if (mode === "AVERAGE") {
      const sum = metricData.reduce((accum, val) => (accum += val.value), 0);
      if (sum <= 0) return { value: 0, pct: 0 };
      const avg = sum / metricData.length;
      const perc = Math.floor((avg / max) * 100);
      return { value: Math.floor(avg), pct: perc };
    }
  }, [mode, metricData]);

  // value = 75;

  const radialOptions = {
    ...defaultChartOptions,
    chart: {
      height: 350,
      width: 350,
      type: "radialBar",
      background: primaryDark,
    },
    series: [value.pct],
    labels: [metric],
    theme: {
      mode: "dark",
    },
    fill: {
      colors: [COLORS[index]],
      type: value > 70 ? "gradient" : "solid",
      gradient: {
        shade: "dark",
        type: "diagonal2",
        shadeIntensity: 0.1,
        gradientToColors: [value > 70 ? "red" : COLORS[index]],
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100],
      },
    },
    plotOptions: {
      radialBar: {
        track: {
          background: "#1b1e21",
        },
        dataLabels: {
          showOn: "always",
          name: {
            offsetY: -95,
            show: true,
            color: "#ffffff",
            fontSize: "15px",
          },

          value: {
            show: true,
            fontSize: "24px",
            fontWeight: 400,
            offsetY: -10,
            // color: getColor(value.value),
            formatter: function (val) {
              return `${val}%`;
            },
          },
        },
      },
    },
  };

  return (
    <React.Fragment>
      <ReactApexChart
        type="radialBar"
        options={radialOptions}
        series={[value.pct]}
        height="250"
        width={250}
      />
      <Typography
        style={{
          color: getMaxColor(value.value, max),
          fontSize: 20,
        }}
        className={classes.radialDescription}
      >
        {value.value} / {max}
      </Typography>
    </React.Fragment>
  );
}

export default MetricDial;
