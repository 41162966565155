import React, { useMemo, useState } from "react";
import { Card, CardContent, Typography, NativeSelect } from "@material-ui/core";
import ReactApexChart from "react-apexcharts";
import moment from "moment-timezone";

import { defaultChartOptions } from "../../services/ChartingService";
import { useEffect } from "react";

const INTERVALS = {
  MINUTE: "minute",
  HOUR: "hour",
};

const intervalOptions = [INTERVALS.MINUTE, INTERVALS.HOUR];

function getMilli(input) {
  return Number(moment(input).tz("Europe/Madrid").format("x"));
}

function HistogramWidget({ data, timeInterval }) {
  const [granularMetric, setGranularMetric] = useState(INTERVALS.HOUR);
  const [numberGranular, setNumberGranular] = useState(1);

  const isToday = useMemo(() => {
    return !timeInterval || moment().isSame(timeInterval, "day");
  }, [timeInterval]);

  useEffect(() => {
    if (!isToday && granularMetric === INTERVALS.MINUTE) {
      setGranularMetric(INTERVALS.HOUR);
    }
  }, [isToday]);

  function getIntervalNumber() {
    if (granularMetric === INTERVALS.HOUR) {
      return Math.floor(
        isToday
          ? 24
          : 24 +
              Number(moment().tz("Europe/Madrid").get("hour")) +
              10 / numberGranular
      );
    }

    if (granularMetric === INTERVALS.MINUTE) {
      return Math.floor(1440 / numberGranular);
    }
  }

  const series = useMemo(() => {
    if (!data) return false;
    const intervals = [];
    const aResult = [];
    const categories = [];

    if (!isToday && granularMetric === INTERVALS.MINUTE) return [];

    for (let i = 0; i < getIntervalNumber(); i++) {
      let start;
      let end;

      if (granularMetric === INTERVALS.HOUR) {
        start = Number(
          moment()
            .tz("Europe/Madrid")
            .subtract("day", isToday ? 0 : 1)
            .hour(i * numberGranular)
            .startOf(granularMetric)
            .format("x")
        );
        end = Number(
          moment()
            .tz("Europe/Madrid")
            .subtract("day", isToday ? 0 : 1)
            .hour(i * numberGranular)
            .add(granularMetric, numberGranular - 1)
            .endOf(granularMetric)
            .format("x")
        );
      }
      if (granularMetric === INTERVALS.MINUTE) {
        start = Number(
          moment()
            .startOf("day")
            .add(granularMetric, i * numberGranular)
            .startOf(granularMetric)
            .format("x")
        );
        end = Number(
          moment()
            .startOf("day")
            .add(granularMetric, (i + 1) * numberGranular)
            .endOf(granularMetric)
            .format("x")
        );
      }
      intervals.push([start, end]);
      categories.push(`${moment(start)}`);
      aResult.push([]);
    }

    data.forEach((item, idx) => {
      const time = Number(
        moment(item.timestamp).tz("Europe/Madrid").format("x")
      );

      const interval = intervals.findIndex(
        (iv) => time >= iv[0] && time <= iv[1]
      );
      if (interval < 0) return;

      aResult[interval].push(item.value);
    });

    const finalResult = [];

    for (let i = 0; i < aResult.length; i++) {
      const interval = aResult[i];
      if (!interval.length) {
        finalResult.push(0);
        continue;
      }
      if (interval.length === 1) {
        if (i === 0) {
          finalResult.push(interval[0]);
        } else {
          if (aResult[i - 1].length) {
            finalResult.push(aResult[i - i][aResult[i - 1].length]);
          } else {
            finalResult.push(interval[0]);
          }
        }
        continue;
      }

      finalResult.push(interval[interval.length - 1] - interval[0]);
    }

    return { series: [{ name: "Afluencia", data: finalResult }], categories };
  }, [data, granularMetric, numberGranular]);

  const options = {
    ...defaultChartOptions,
    chart: {
      ...defaultChartOptions.chart,
      type: "histogram",
      height: 380,
      // foreColor: "#999",
      toolbar: {
        show: true,
      },
    },
    plotOptions: {
      bar: {
        columnWidth: "100%",
      },
    },
    dataLabels: {
      enabled: granularMetric === INTERVALS.MINUTE ? false : true,
      formatter: (val) => {
        if (val === 0) return "";
        return val;
      },
    },
    states: {
      active: {
        allowMultipleDataPointsSelection: true,
      },
    },
    xaxis: {
      categories: series && series.categories,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: granularMetric === INTERVALS.HOUR,
        formatter: function (value, timestamp, index) {
          const item = moment(timestamp).tz("Europe/Madrid").format("HH:mm");
          return item;
        },
      },
    },
    yaxis: {
      tickAmount: 4,
      labels: {
        offsetX: -5,
        offsetY: -5,
      },
    },
    grid: {
      show: true,
      borderColor: "#1b1e21",
    },
    theme: {
      mode: "dark",
    },
    tooltip: {
      x: {
        show: true,
        formatter: (val) => {
          return moment(val).tz("Europe/Madrid").format("HH:mm");
        },
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        shadeIntensity: 0.1,
        type: "vertical",
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 0.5,
        stops: [0, 100],
      },
    },
  };

  function handleTypeChange(e) {
    const { value } = e.target;
    if (value === "hour") {
      setGranularMetric("hour");
      setNumberGranular(1);
    } else {
      setGranularMetric("minute");
      setNumberGranular(30);
    }
  }

  function handleNumberChange(e) {
    const { value } = e.target;
    setNumberGranular(value);
  }

  return (
    <Card>
      <CardContent>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Typography>Afluencia Histogram</Typography>
          <NativeSelect
            style={{ marginLeft: 20 }}
            value={granularMetric}
            onChange={handleTypeChange}
          >
            <option value="hour">Hour</option>
            {/* {isToday && <option value="minute">Minute</option>} */}
          </NativeSelect>
          <NativeSelect
            style={{ marginLeft: 20 }}
            value={numberGranular}
            onChange={handleNumberChange}
          >
            {granularMetric === "hour" &&
              Array(24)
                .fill(1)
                .map((_, idx) => <option value={idx + 1}>{idx + 1}</option>)}
            {granularMetric === "minute" && (
              <React.Fragment>
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={15}>15</option>
                <option value={20}>20</option>
                <option value={25}>25</option>
                <option value={30}>30</option>
              </React.Fragment>
            )}
          </NativeSelect>
        </div>
        {series && (
          <ReactApexChart
            options={options}
            series={series.series}
            type="bar"
            height="250"
          />
        )}
      </CardContent>
    </Card>
  );
}

export default HistogramWidget;
