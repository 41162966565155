import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  makeStyles,
  useMediaQuery,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import { useContext } from "react";
import { CounterContext } from "../../../../contextStore";

const useStyles = makeStyles(() => ({
  container: {
    margin: 40,
  },
  title: {
    marginBottom: 20,
  },
}));

function CounterList() {
  const classes = useStyles();
  const { counters } = useContext(CounterContext);
  const minWidthMatches = useMediaQuery("(min-width:900px)");

  return (
    <div className={classes.container}>
      <Typography className={classes.title}>Counter List</Typography>
      <Card>
        <CardContent>
          {minWidthMatches && (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Identifier</TableCell>
                  <TableCell>Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {counters.map((counter) => (
                  <TableRow key={counter.counterId}>
                    <TableCell>{counter.mnemonic}</TableCell>
                    <TableCell>{counter.name}</TableCell>
                    <TableCell>{counter.value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
          {!minWidthMatches && (
            <List>
              {counters.map((counter) => (
                <React.Fragment>
                  <ListItem key={counter.counterId}>
                    <ListItemText primary={`Name: ${counter.mnemonic}`} />
                  </ListItem>
                  <ListItem key={counter.counterId}>
                    <ListItemText primary={`Identifier: ${counter.name}`} />
                  </ListItem>
                  <ListItem divider key={counter.counterId}>
                    <ListItemText primary={`Value: ${counter.value}`} />
                  </ListItem>
                </React.Fragment>
              ))}
            </List>
          )}
        </CardContent>
      </Card>
    </div>
  );
}

export default CounterList;
