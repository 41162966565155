import React from "react";

const PageWrapper = ({
  children,
  verticalCenter,
  horizontalCenter,
  fullHeight,
  margin,
  scrollable,
  style = {},
  id,
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: horizontalCenter ? "center" : "inherit",
        justifyContent: verticalCenter ? "center" : "inherit",
        height: fullHeight ? "calc(100vh - 66px)" : "inherit",
        margin,
        overflow: scrollable ? "scroll" : "inherit",
        marginLeft: 50,
        marginTop: 40,
        ...style,
      }}
      id={id}
    >
      {children}
    </div>
  );
};

export default PageWrapper;
