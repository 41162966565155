import React from "react";
import {
  Card,
  CardContent,
  IconButton,
  makeStyles,
  Tooltip,
  ButtonGroup,
  Button,
} from "@material-ui/core";
import { Save } from "@material-ui/icons";
import { useState } from "react";
import SaveToPdfDialog from "../../../../../../components/SaveToPdfDialog";
import moment from "moment-timezone";
import { useMemo } from "react";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    paddingTop: 0,
    justifyContent: "space-between",
  },
  icon: {
    margin: "0px 5px",
    border: "1px solid white",
    "&:hover": {
      color: theme.palette.secondary.main,
      borderColor: theme.palette.secondary.main,
    },
  },
}));

function DashToolbar({ timeIntervalRef, loadData }) {
  const classes = useStyles();
  const [showSaveToPdfDialog, setShowSaveToPdfDialog] = useState(false);

  function handleYesterdayClick() {
    const time = moment().subtract("day", 1).startOf("day");
    timeIntervalRef.current = time;
    loadData();
  }

  function handleTodayClick() {
    const time = false;
    timeIntervalRef.current = time;
    loadData();
  }

  const isToday = useMemo(() => {
    return (
      !timeIntervalRef.current ||
      moment().isSame(timeIntervalRef.current, "day")
    );
  }, [timeIntervalRef]);

  const isYesterday = useMemo(() => {
    return moment()
      .subtract("day", 1)
      .startOf("day")
      .isSame(timeIntervalRef.current, "day");
  }, [timeIntervalRef]);

  const is7Days = useMemo(() => {
    return moment()
      .subtract("day", 7)
      .startOf("day")
      .isSame(timeIntervalRef.current, "day");
  }, [timeIntervalRef]);

  function handle7DaysClick() {
    const time = moment().subtract("day", 7).startOf("day");
    timeIntervalRef.current = time;
    loadData();
  }

  return (
    <React.Fragment>
      {showSaveToPdfDialog && (
        <SaveToPdfDialog handleClose={() => setShowSaveToPdfDialog(false)} />
      )}
      <div className={classes.container}>
        <ButtonGroup color="secondary">
          <Button
            onClick={handleTodayClick}
            size="small"
            variant={isToday ? "contained" : "outlined"}
          >
            Today
          </Button>
          <Button
            onClick={handleYesterdayClick}
            variant={isYesterday ? "contained" : "outlined"}
            size="small"
          >
            Yesterday
          </Button>
          <Button
            onClick={handle7DaysClick}
            variant={is7Days ? "contained" : "outlined"}
            size="small"
          >
            7 Days
          </Button>
        </ButtonGroup>
        <Tooltip title="Save to PDF">
          <IconButton
            onClick={() => setShowSaveToPdfDialog(true)}
            className={classes.icon}
          >
            <Save />
          </IconButton>
        </Tooltip>
      </div>
    </React.Fragment>
  );
}

export default DashToolbar;
