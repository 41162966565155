export const APP_NAME = "Cancerbero";

const UserPoolId = "eu-west-2_4wQiOP73R";
const ClientId = "103afhrruf2tj1bikq1rffg0su";

const domain = window.location.host;

export const cognitoConfig = {
  UserPoolId,
  ClientId,
  AppWebDomain: domain,
  // TokenScopesArray: ['openid', 'email', 'phone'],
  RedirectUri: "/",
};

export const apiEndpoint =
  "https://l1kjq6rz7c.execute-api.eu-west-3.amazonaws.com/prd";
