import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  makeStyles,
  useMediaQuery,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import { useContext } from "react";
import { DeviceContext } from "../../../../contextStore";

const useStyles = makeStyles(() => ({
  container: {
    margin: 40,
  },
  title: {
    marginBottom: 20,
  },
}));

function CheckLight({ off }) {
  return (
    <div
      style={{
        height: 10,
        width: 10,
        borderRadius: 5,
        border: "0px solid white",
        backgroundColor: off ? "red" : "green",
        boxShadow: `0 0 4px ${off ? "red" : "#2ba805"}`,
      }}
    />
  );
}

function DeviceList() {
  const classes = useStyles();
  const { devices } = useContext(DeviceContext);
  const minWidthMatches = useMediaQuery("(min-width:900px)");

  return (
    <div className={classes.container}>
      <Typography className={classes.title}>Device List</Typography>
      <Card>
        <CardContent>
          {minWidthMatches && (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">Status</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>IP</TableCell>
                  <TableCell>Port</TableCell>
                  <TableCell>Username</TableCell>
                  <TableCell>Password</TableCell>
                  <TableCell>Model</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {devices.map((device) => (
                  <TableRow key={device.deviceId}>
                    <TableCell padding="checkbox">
                      <CheckLight off={device.off} />
                    </TableCell>

                    <TableCell>{device.name}</TableCell>
                    <TableCell>{device.ipPort.split(":")[0]}</TableCell>
                    <TableCell>{device.ipPort.split(":")[0]}</TableCell>
                    <TableCell>{device.username}</TableCell>
                    <TableCell>*****</TableCell>
                    <TableCell>{device.model}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
          {!minWidthMatches && (
            <List>
              {devices.map((device) => (
                <React.Fragment>
                  <ListItem key={device.deviceId}>
                    <ListItemText
                      primary={`Status: ${device.off ? "OFF" : "ON"}`}
                    />
                  </ListItem>
                  <ListItem key={device.deviceId}>
                    <ListItemText primary={`Name: ${device.name}`} />
                  </ListItem>
                  <ListItem key={device.deviceId}>
                    <ListItemText
                      primary={`IP: ${device.ipPort.split(":")[0]}`}
                    />
                  </ListItem>
                  <ListItem key={device.deviceId}>
                    <ListItemText
                      primary={`Port: ${device.ipPort.split(":")[1]}`}
                    />
                  </ListItem>
                  <ListItem key={device.deviceId}>
                    <ListItemText primary={`Username: ${device.username}`} />
                  </ListItem>
                  <ListItem key={device.deviceId}>
                    <ListItemText primary={`Password: ******`} />
                  </ListItem>
                  <ListItem divider key={device.deviceId}>
                    <ListItemText primary={`Model: ${device.model}`} />
                  </ListItem>
                </React.Fragment>
              ))}
            </List>
          )}
        </CardContent>
      </Card>
    </div>
  );
}

export default DeviceList;
