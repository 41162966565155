import HttpService from "../HttpService";
import { apiEndpoint } from "../../config";

const customerUrl = `${apiEndpoint}/customer`;

export async function createCustomer({ email, sub, name }) {
  try {
    await HttpService.post(customerUrl, {
      email,
      sub,
      name,
    });
  } catch (e) {
    throw e;
  }
}

export async function getCustomer() {
  try {
    const response = await HttpService.get(customerUrl);
    const { data } = response;
    return data;
  } catch (e) {
    throw e;
  }
}
