import React from "react";
import {
  Card,
  CardContent,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { defaultChartOptions } from "../../../../../../services/ChartingService";
import { useMemo } from "react";
import ReactApexChart from "react-apexcharts";
import moment from "moment-timezone";
import { primaryDark } from "../../../../../../styles/colors";

const COLORS = [
  "#1068ef",
  "#447825",
  "#9100ff",
  "#25da57",
  "#bb35ca",
  "#5dadec",
  "#A64CA6",
  "#FF6666",
];

const HistoricMetricWidget = React.memo(
  ({ metric, metricData, max, index }) => {
    const minWidthForToolbar = useMediaQuery("(min-width:600px)");

    const options = {
      ...defaultChartOptions,
      chart: {
        ...defaultChartOptions.chart,
        stacked: false,
        zoom: {
          type: "x",
          enabled: true,
          autoScaleYaxis: true,
        },
        toolbar: {
          show: minWidthForToolbar,
        },
      },
      plotOptions: {
        line: {
          curve: "smooth",
        },
      },
      dataLabels: {
        enabled: false,
      },

      markers: {
        size: 0,
        style: "full",
      },
      colors: [COLORS[index]],
      title: {
        text: metric,
        align: "left",
      },
      stroke: {
        width: 1,
        curve: "smooth",
        lineCap: "round",
      },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 0.5,
          type: "horizontal",
          inverseColors: false,
          opacityFrom: 0.5,
          opacityTo: 0.8,
          stops: [0, 90, 100],
        },
      },
      yaxis: {
        show: true,
        title: {
          text: "Value",
        },
      },
      xaxis: {
        datetimeUTC: true,
        type: "datetime",
        tickPlacement: "on",
        labels: {
          datetimeUTC: false,
          formatter: function (value, timestamp, index) {
            return moment(timestamp).tz("Europe/Madrid").format("HH:mm");
          },
        },
      },
      grid: {
        show: true,
        borderColor: "#1b1e21",
      },
      theme: {
        mode: "dark",
      },
    };

    const series = useMemo(() => {
      if (!metricData) return false;
      const result = [];

      metricData.forEach((item, idx) => {
        result.push([item.timestamp, item.value]);
      });
      return result;
    }, [metricData]);

    return (
      <Card>
        <CardContent>
          <ReactApexChart
            options={options}
            series={[{ name: "Value", data: series }]}
            type="area"
            height="250"
          />
        </CardContent>
      </Card>
    );
  }
);

export default HistoricMetricWidget;
