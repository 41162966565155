import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
  Button,
} from "@material-ui/core";
import { useContext } from "react";
import html2canvas from "html2canvas";
import JsPDF from "jspdf";
import { ProjectContext, QueryContext } from "../../contextStore";

function SaveToPdfDialog({ handleClose }) {
  const { currentProject } = useContext(ProjectContext);
  const query = useContext(QueryContext);
  console.log(currentProject);

  async function saveReport() {
    query.show();
    const fileName = currentProject.name;

    const element = document.getElementById("Home_Page");
    try {
      const canvas = await html2canvas(element);

      const img = canvas.toDataURL("image/png");
      const doc = new JsPDF({
        orientation: "portrait",
        unit: "mm",
        format: [element.offsetHeight / 2, element.offsetWidth / 2],
      });
      const { width, height } = doc.internal.pageSize;
      doc.addImage(img, "PNG", 0, 0, width, height, null, "FAST");
      doc.save(fileName);
      query.hide();
      handleClose();
    } catch (e) {
      console.log("error: ", e);
      query.hide();
    }
  }
  return (
    <Dialog open fullWidth>
      <DialogTitle>Create Report</DialogTitle>
      <DialogContent>
        <Typography>
          Would you like to create a report and save to a pdf?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined" color="secondary">
          Cancel
        </Button>
        <Button
          disabled={true}
          onClick={saveReport}
          variant="contained"
          color="secondary"
        >
          {/* Save to PDF */}
          Coming Soon
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SaveToPdfDialog;
