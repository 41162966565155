import React, { useState, useEffect } from "react";
import AppRouter from "./AppRouter";
import {
  createMuiTheme,
  MuiThemeProvider,
  CircularProgress,
} from "@material-ui/core";
import { primaryDark } from "./styles/colors";
import {
  UserContext,
  ProjectContext,
  DeviceContext,
  CounterContext,
  MetricsContext,
  QueryContext,
} from "./contextStore";
import { getSession } from "./services/CognitoService";
import { getCustomer } from "./services/CustomerService";
import PageWrapper from "./components/PageWrapper";
import { getProjects } from "./services/ProjectService";
import { getDevices } from "./services/DeviceService";
import { getCounters } from "./services/CounterService";
import { getMetrics } from "./services/MetricService";
import LinearBuffer from "./components/LinearBuffer";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: primaryDark,
    },
    secondary: {
      main: "#19d0e6",
    },
    type: "dark",
  },
  overrides: {
    MuiPaper: {
      root: {
        backgroundColor: primaryDark,
      },
      elevation4: {
        boxShadow:
          "0px 1px 2px -1px rgba(0,0,0,0.2),0px -1px 5px 0px rgba(0,0,0,0.14),0px 1px 4px 0px rgba(0,0,0,0.12)",
      },
    },
    MuiFormLabel: {
      root: {
        "&$focused": {
          color: "lightgray",
        },
      },
    },
    MuiTypography: {
      root: {
        color: "white",
      },
    },
    MuiButton: {
      root: {
        textTransform: "none",
        color: "black",
      },
      contained: {
        boxShadow: 0,
      },
    },
  },
});

function App() {
  const [user, setUser] = useState(false);
  const [projects, setProjects] = useState([]);
  const [devices, setDevices] = useState([]);
  const [currentProject, setCurrentProject] = useState(false);
  const [counters, setCounters] = useState([]);
  const [metrics, setMetrics] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showQuery, setShowQuery] = useState(false);

  async function checkForUser() {
    try {
      await getSession();
      const userResp = await getCustomer();
      const promises = [
        getProjects(),
        getDevices(),
        getCounters(),
        getMetrics(),
      ];
      const [
        projectsResp,
        devicesResp,
        countersResponse,
        metricsResponse,
      ] = await Promise.all(promises);
      setProjects(projectsResp);
      setCurrentProject(projectsResp[0]);
      setDevices(devicesResp);
      setCounters(countersResponse);
      const sortedMetrics = metricsResponse.sort(
        (a, b) => Boolean(b.total) - Boolean(a.total)
      );
      setMetrics(sortedMetrics);
      setUser(userResp);
      setLoading(false);
    } catch (e) {
      console.log("e", e);
      setLoading(false);
    }
  }

  function updateProjects(newProject) {
    const copy = projects.slice(0);
    copy.push(newProject);
    setProjects(copy);
    setCurrentProject(newProject);
  }

  function updateDevices(newDevice) {
    const copy = devices.slice(0);
    copy.push(newDevice);
    setDevices(copy);
  }

  function updateCounters(newCounters) {
    let copy = devices.slice(0);
    copy = [...copy, ...newCounters];
    setCounters(copy);
  }

  function updateMetrics(newMetric) {
    const copy = metrics.slice(0);
    copy.push(newMetric);
    setMetrics(copy);
  }

  useEffect(() => {
    checkForUser();
  }, []);

  return (
    <React.Fragment>
      <MuiThemeProvider theme={theme}>
        <QueryContext.Provider
          value={{
            show: () => setShowQuery(true),
            hide: () => setShowQuery(false),
            active: showQuery,
          }}
        >
          <MetricsContext.Provider value={{ metrics, updateMetrics }}>
            <CounterContext.Provider value={{ counters, updateCounters }}>
              <DeviceContext.Provider value={{ devices, updateDevices }}>
                <ProjectContext.Provider
                  value={{
                    projects,
                    currentProject,
                    updateProjects,
                    setCurrentProject,
                  }}
                >
                  <UserContext.Provider value={user}>
                    {loading ? (
                      <PageWrapper verticalCenter horizontalCenter>
                        <CircularProgress size={120} color="secondary" />
                        {/* <img src={require("./assets/logo.png")} height={200} /> */}
                      </PageWrapper>
                    ) : (
                      <React.Fragment>
                        {showQuery && <LinearBuffer />}
                        <AppRouter />
                      </React.Fragment>
                    )}
                  </UserContext.Provider>
                </ProjectContext.Provider>
              </DeviceContext.Provider>
            </CounterContext.Provider>
          </MetricsContext.Provider>
        </QueryContext.Provider>
      </MuiThemeProvider>
    </React.Fragment>
  );
}

export default App;
