import React from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  makeStyles,
  IconButton,
} from "@material-ui/core";
import ProjectSelector from "../../pages/HomePage/components/ProjectSelector";
import { useContext } from "react";
import { ProjectContext, UserContext } from "../../contextStore";
import { ExitToApp } from "@material-ui/icons";
import { signOut } from "../../services/CognitoService";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(() => ({
  title: {
    cursor: "pointer",
  },
  toolbar: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    minHeight: 50,
    height: 50,
    paddingLeft: 20,
  },
  container: {
    zIndex: 2,
  },
  loginButton: {
    marginRight: 10,
  },
}));

const logo = require("../../assets/logo.png");
function NavBar() {
  const classes = useStyles();
  const { projects, currentProject } = useContext(ProjectContext);
  const user = useContext(UserContext);
  const history = useHistory();

  function handleLogOut() {
    signOut();
    window.location.href = "/";
  }

  function handleLoginClick() {
    history.push("/login");
  }
  return (
    <AppBar className={classes.container} position="fixed">
      <Toolbar disableGutters className={classes.toolbar}>
        <img src={logo} height={40} />
        {/* <Typography variant="h6" className={classes.title}>
          C
        </Typography> */}
        {user && projects && !!projects.length && (
          <ProjectSelector
            projects={projects}
            currentProject={currentProject}
          />
        )}
        {user ? (
          <IconButton onClick={handleLogOut}>
            <ExitToApp />
          </IconButton>
        ) : (
          <Button
            onClick={handleLoginClick}
            color="secondary"
            variant="contained"
            className={classes.loginButton}
          >
            Login
          </Button>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default NavBar;
