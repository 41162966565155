import { v1 } from "uuid";
import HttpService from "../HttpService";
import { apiEndpoint } from "../../config";

const counterUrl = `${apiEndpoint}/counter`;

export async function getCounters() {
  try {
    const response = await HttpService.get(counterUrl);
    const { data } = response;
    return data;
  } catch (e) {
    throw e;
  }
}

export async function createCounter({ mnemonic, deviceId, name, projectId }) {
  try {
    const response = await HttpService.post(counterUrl, {
      name,
      counterId: v1(),
      deviceId,
      mnemonic,
      value: 0,
      projectId,
    });
    const { data } = response;
    return data;
  } catch (e) {
    throw e;
  }
}
