import { v1 } from "uuid";
import HttpService from "../HttpService";
import { apiEndpoint } from "../../config";

const deviceUrl = `${apiEndpoint}/device`;

export async function getDevices() {
  try {
    const response = await HttpService.get(deviceUrl);
    const { data } = response;
    return data;
  } catch (e) {
    throw e;
  }
}

export async function createDevice({
  name,
  ipPort,
  username,
  password,
  model,
  projectId,
}) {
  try {
    const response = await HttpService.post(deviceUrl, {
      name,
      deviceId: v1(),
      ipPort,
      username,
      password,
      model,
      projectId,
    });
    const { data } = response;
    return data;
  } catch (e) {
    throw e;
  }
}
