export function distanceBetweenPoints(x1, y1, x2, y2) {
  const a = x1 - x2;
  const b = y1 - y2;

  return Math.sqrt(a * a + b * b);
}

export function findAngle(cx, cy, ex, ey) {
  var dy = ey - cy;
  var dx = ex - cx;
  var theta = Math.atan2(dy, dx); // range (-PI, PI]
  theta *= 180 / Math.PI; // rads to degs, range (-180, 180]
  //if (theta < 0) theta = 360 + theta; // range [0, 360)
  return theta;
}

export function getLastMetricData(metricValues, idx) {
  if (!metricValues[idx].length) return 0;
  return metricValues[idx][metricValues[idx].length - 1].value < 0
    ? 0
    : metricValues[idx][metricValues[idx].length - 1].value;
}
