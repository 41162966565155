import React from "react";
import {
  List,
  Drawer,
  ListItem,
  IconButton,
  ListItemIcon,
  makeStyles,
  Tooltip,
  useTheme,
} from "@material-ui/core";
import {
  Dashboard,
  Settings,
  Notifications,
  PersonPinCircle,
  Person,
} from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import { useContext } from "react";
import { ProjectContext } from "../../contextStore";

const useStyles = makeStyles(() => ({
  container: {
    marginTop: 50,
    height: "calc(100% - 50px)",
    zIndex: 1,
  },
  listItemIcon: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

const options = [
  {
    tooltip: "Dashboard",
    path: "/",
    icon: () => <Dashboard />,
  },
  {
    tooltip: "Settings",
    path: "/settings",
    icon: () => <Settings />,
  },
  // {
  //   tooltip: "Alarms",
  //   path: "/alarms",
  //   icon: () => <Notifications />,
  // },
  // {
  //   tooltip: "Profile",
  //   path: "/profile",
  //   icon: () => <Person />,
  // },
];

function SideBar() {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();

  const { projects } = useContext(ProjectContext);

  function isActive(path) {
    const { pathname } = window.location;
    if (pathname === path) return true;
    return false;
  }

  function handleIconClick(path) {
    history.push(path);
  }

  return (
    <Drawer PaperProps={{ className: classes.container }} variant="permanent">
      <List>
        {options.map((option) => (
          <ListItem key={option.tooltip} disableGutters>
            <Tooltip enterDelay={1000} title={option.tooltip}>
              <ListItemIcon className={classes.listItemIcon}>
                <IconButton
                  onClick={() => handleIconClick(option.path)}
                  disabled={!projects || !projects.length}
                  style={{
                    color:
                      isActive(option.path) && projects && projects.length
                        ? theme.palette.secondary.main
                        : "lightgray",
                  }}
                >
                  {option.icon()}
                </IconButton>
              </ListItemIcon>
            </Tooltip>
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
}

export default SideBar;
